<template >
    <div class="flex">
        <div class="profile-head">

            <img v-if="profile && profile.photo" :src="profile.photo" alt="Profile Picture" />
            <div class="img" v-else>
                {{profile?.name?.split(' ')?.map(word => word.charAt(0)).join('')}}
            </div>
            <div class="user-name">
              <h5>{{profile?.name}}</h5>
              <span class="email-address">
                {{ profile?.emailAddress }}
                </span>
            </div>
          </div>
          <div class="action-box">
          <a  class="btn btn-info" @click.prevent="openCompose()" style="float:right;">New Conversation</a>
        </div>
    </div>
</template>
  
  <script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  
  export default {
    props: {
      email: {
        type: String,
        required: true,
      },
      profile:{
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        showQuickAccess: false,
        showFunctions: false,
        showSearch: false,
        searchQuery: "",
        functionalities: [
          { label: "Funcionalidade 1", icon: "fas fa-chart-bar" },
          { label: "Funcionalidade 2", icon: "fas fa-headset" },
          { label: "Funcionalidade 3", icon: "fas fa-comment" },
          { label: "Funcionalidade 4", icon: "fas fa-adjust" },
        ],
      };
    },
    computed: {
      ...mapGetters("auth", ["getActiveWorkspace", "getAuthenticatedUser"]),
      getDateValues() {
        return (date) => moment(date).fromNow();
      },
    },
    methods: {
        openCompose(){
                this.$showMailCompose(this.email)
        },
      toggleDropdown(type) {
        if (type === "quickAccess") this.showQuickAccess = !this.showQuickAccess;
        if (type === "functions") this.showFunctions = !this.showFunctions;
      },
      toggleSearch() {
        this.showSearch = !this.showSearch;
      },
      performSearch() {
        console.log("Searching for:", this.searchQuery);
      },
      login() {
        console.log("Login button clicked");
      },
    },
  };
  </script>
  
  <style scoped>
  .flex{
    display: flex;
  }

  .profile-head {
    display: flex;
    align-items: center;
    padding: 10px;
    
    color: #333;
    /* border-bottom: 1px solid #e0e0e0;
    background-color: #f0f4f8;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  }
  .action-box{
    float:left;
    width:100%;
    align-content: center;
  }
  
  .profile-head img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    border: 2px solid #007bff;
  }

  .profile-head .img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    border: 2px solid #007bff;
    align-content: center;
    text-align: center;
    color: rgba(255, 174, 0, 0.941);
    font-size: large;
    font-weight: 600;
    text-transform: uppercase;
  }

  
.user-name h5 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #007bff;
  }
  
  .user-name .email-address {
    font-size: 14px;
    color: #6c757d;
  }
  @media (max-width: 768px) {

  
    .profile-head {
      flex-direction: column;
      text-align: center;
    }
  
    .profile-head img {
      margin-bottom: 10px;
    }
    .profile-head .img {
        margin-bottom: 10px;
      }
  
    .inbox-nav li a {
      font-size: 14px;
    }
  }
  </style>
  